import React, { useEffect, Fragment } from "react";
import { Container, Card, CardBody, Button } from "reactstrap";
import BreadCrumbs from "../components/Layout/BreadCrumbs";
import TitleBar from "../components/Layout/TitleBar";
import SEO from "../components/SEO";
import documents from "../assets/data/documents";
// import { Helmet } from "react-helmet";

const DocumentsPage = () => {
    useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <Fragment>
           <SEO
                title="Documents"
                description="Documents pertaining to recent cannabis and psychedelics law and litigation in Ontario and Canada handled by Cannabis and Psychedelics Law Group LLP." 
            />

            <div className="wrapper mt-5">
                <div className="main bg-grey">
                    <Container>
                        <TitleBar title="Documents" />
                        <BreadCrumbs page="Documents" />
                        {documents.map((item, i) => {
                            return item.download ? (
                                <Card key={i} className="mb-3">
                                    <CardBody className="p-3">
                                        <div style={{ display: "flex" }} className="justify-content-between">
                                            <h6 style={{ width: "75%" }} className="m-0 mr-2">
                                                {item.title}
                                            </h6>
                                            <a href={item.doc} target="_blank" rel="noopener noreferrer" download>
                                                <Button
                                                block
                                                className="m-0 p-2"
                                                style={{
                                                    height: "40px",
                                                    width: "40%",
                                                    minWidth: "120px",
                                                }}
                                                >
                                                View Document
                                                </Button>
                                            </a>
                                        </div>
                                    </CardBody>
                                </Card>
                            ) : 
                            <Card key={i} className="mb-3">
                                    <CardBody className="p-3">
                                        <div style={{ display: "flex" }} className="justify-content-between">
                                            <h6 style={{ width: "75%" }} className="m-0 mr-2">
                                                {item.title}
                                            </h6>
                                            <a href={item.doc} target="_blank" rel="noopener noreferrer" >
                                                <Button
                                                block
                                                className="m-0 p-2"
                                                style={{
                                                    height: "40px",
                                                    width: "40%",
                                                    minWidth: "120px",
                                                }}
                                                >
                                                View Document
                                                </Button>
                                            </a>
                                        </div>
                                    </CardBody>
                                </Card>
                        })}
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default DocumentsPage;