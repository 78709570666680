export default [
    {
        title: "Veteran access to psilocybin for PTSD",
        doc: "/blog/veteran-access-to-psilocybin-for-PTSD",
        download: false,
    },
    {
        title: "Letter to Minister in Support of Section 56 Application",
        doc: "/documents/Let-01Minister02.pdf",
        download: true,
    },
    {
        title: "Master Corporal Scott Atkinson’s section 56 application",
        doc: "/documents/MCpl_Atkinson_S. 56_Application.pdf",
        download: true,
    },
    {
        title: "Submission re: accessing restricted drugs under Special Access Program",
        doc: "/documents/SAP_Submission.pdf",
        download: true,
    },
    {
        title: "Use of Injectable Ketamine to Treat Psychiatric Illnesses in an Out-of Hospital Premises in Ontario",
        doc: "/documents/Ketamine_Therapy_Ontario.pdf",
        download: true,
    },
    {
        title: "Submission re: Micro class and nursery licenses",
        doc: "/documents/Submission_MicroClass_Nurseries.pdf",
        download: true,
    },
];